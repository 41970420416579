import React, { useState, useEffect } from "react";
import { Table, Input, Drawer, Button, Tooltip } from 'antd';
import axios from 'axios';
import { host } from '../config.js';
import { MoneyCollectOutlined } from '@ant-design/icons';

const Payments = () => {

    let [payments, setPayments] = useState([]);
    let [isVisible, setIsVisible] = useState(false);
    let [payment, setPayment] = useState({
        id: 0,
        price: 0,
    })

    const getPayments = () => {
        axios.get(host + "/payments?username=" + localStorage.getItem("username") + "&password=" + localStorage.getItem("password"), {}).then((data) => {
            if (data.data.error) {
                alert(data.data.message)
            } else {
                setPayments(data.data.message);
            }

        }).catch((err) => {
            alert(err.message);
        });
    }

    const columns = [

        {
            title: '#',
            dataIndex: 'id',
        },
        {
            title: "Գին",
            dataIndex: "amount",
            key: "amount"
        },
        {
            title: 'Այցելություններ',
            dataIndex: 'visits_count',
            key: 'visits_count',
        },
        {
            title: 'Պարտք',
            dataIndex: 'must_be_payed',
            key: 'must_be_payed',
        },
        {
            title: 'Այցելություններ',
            dataIndex: 'visits_count',
            key: 'visits_count',
        },
        {
            title: 'Վճարող',
            render: (text, record) => {
                return record.client ? record.client.full_name : "N/A"
            }
        },
        {
            title: '...',
            render: (text, record) => {
                console.log(record)
                if (record.not_payed_full) {
                    return <Tooltip onClick={() => openPaymentDrawer(record.id)} title="Մարել պարտքը">
                        <Button type="primary" shape="circle" icon={<MoneyCollectOutlined />} />
                    </Tooltip>
                }
            }
        },

    ];

    const pay = async () => {
        let data = await axios.post(host + "/payments/pay", {
            username: window.localStorage.getItem("username"),
            password: window.localStorage.getItem("password"),
            id: payment.id,
            price: payment.price
        });

        if (data.data.error) {
            alert(data.data.message)
        } else {
            getPayments();
            setIsVisible(false);
        }
    }

    const openPaymentDrawer = (id) => {
        payment.id = id;
        setPayment({ ...payment });
        setIsVisible(true);
    }
    const onClose = () => {
        setIsVisible(false);
    };

    useEffect(() => {
        getPayments();
    }, [])

    return (
        <div>
            <h1>Վճարումներ</h1>
            <Drawer
                title={`Մարել պարտքը`}
                placement="right"
                size={"large"}
                onClose={onClose}
                visible={isVisible}
            >
                <div className={"form-block"}>
                    <Input size="large" value={payment.price} onInput={(e) => {
                        payment.price = e.target.value;
                        setPayment({ ...payment })
                    }} placeholder="Կանխիկ վերցված գումար" prefix={<MoneyCollectOutlined />} />
                </div>
                <div className={"form-block"}>
                    <Button type="primary" onClick={pay}>
                        Մարել
                    </Button>
                </div>
            </Drawer>
            <Table columns={columns} dataSource={payments} />

        </div>
    )

}

export default Payments;