import React, { useState } from "react";

import { useNavigate } from 'react-router-dom';
import { Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import axios from 'axios'

import { host } from '../config.js'

const Login = () => {
    //6964ACTR
    //AT3
    let [username, setUsername] = useState("");
    let [password, setPassword] = useState("");
    let [loading, setLoading] = useState(false);
    let navigate = useNavigate();

    const doLogin = () => {
        setLoading(true);
        axios.post(host + "/login", { username, password }).then(data => {
            if (data.data.error) {
                alert("ERROR 401")
            } else {
                window.localStorage.setItem("username", username);
                window.localStorage.setItem("password", password);
                setTimeout(() => {
                    setLoading(false);
                    navigate("/data")
                }, 500)
            }
        }).catch(err => {
            alert(err.message);
        })
    }

    return (
        <div className={"center"}>
            <h1 style={{ textAlign: "center" }}>
                Մուտք գործել
            </h1>
            <div className={"form-block"}>
                <Input size="large" value={username} onChange={(e) => {
                    setUsername(e.target.value)
                }} placeholder="Օգտագործողի անունը" prefix={<UserOutlined />} />
            </div>
            <div className={"form-block"}>
                <Input size="large" value={password} onChange={(e) => {
                    setPassword(e.target.value);
                }} placeholder="Գաղտնաբառ" type={"password"} prefix={<LockOutlined />} />
            </div>
            <div className={"form-block"}>
                <Button type="primary" loading={loading} onClick={doLogin}>
                    Մուտք
                </Button>
            </div>
        </div>
    )
}
export default Login;