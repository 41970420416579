import React, { useState, useEffect } from "react";
import { Row, Col, Tooltip, Table, Button, Drawer, Input, Select, Icons } from 'antd';
import axios from 'axios';
import { host } from '../config.js';
import { EditOutlined, PauseCircleOutlined } from '@ant-design/icons';

const { Option } = Select;


function onChange(pagination, filters, sorter, extra) {
    console.log('params', pagination, filters, sorter, extra);
}

const Packages = () => {
    let [packages, setPackages] = useState([]);
    const [visible, setVisible] = useState(false);
    const [size, setSize] = useState("large");
    const [mode, setMode] = useState("add");
    const [loading, setLoading] = useState(false);
    const [packageInfo, setPackageInfo] = useState({
        name: "",
        price: "",
        visits_count: "",
        schedule_type: "",
        schedule_time: "",
    });

    const columns = [

        {
            title: '#',
            dataIndex: 'id',
        },
        {
            title: "Անուն",
            dataIndex: "name",
            key: "nem"
        },
        {
            title: "Գին",
            dataIndex: "price",
            key: "price"
        },
        {
            title: 'Այցելություններ',
            dataIndex: 'visits_count',
            key: 'visits_count',
        },
        {
            title: 'Այցելությունների տևողությունը',
            render: (text, record) => {
                console.log(record);
                return record.schedule_time + " " + record.schedule_type
            }
        },
        {
            title: "Actions",
            dataIndex: "id",
            key: "id",
            render: (id) => {
                return (
                    <div>
                        <Tooltip onClick={() => edit(id)} title="Խմբագրել">
                            <Button style={{ background: "orange" }} shape="circle" icon={<EditOutlined />} />
                        </Tooltip>
                        <Tooltip onClick={() => remove(id)} title="Հեռացնել">
                            <Button type="danger" shape="circle" icon={<PauseCircleOutlined />} />
                        </Tooltip>
                    </div>
                )
            }
        }

    ];


    const remove = (id) => {
        axios.post(host + "/package/remove", {
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password"),
            id,
        }).then((data) => {
            if (data.data.error) {
                alert(data.data.message)
            } else {
                getPackages()
            }
        }).catch((err) => {
            alert(err.message);
        });
    }

    const edit = (id) => {
        let pckg = {}
        for (let i = 0; i < packages.length; i++) {
            if (id === packages[i].id) {

                pckg = packages[i];

                break;
            }
        }
        console.log(pckg)
        openDrawer("edit");
        setPackageInfo({ ...pckg });
    }

    const openDrawer = (mode) => {
        setSize('large');
        setVisible(true);
        setMode(mode);
    };

    const addPackage = () => {
        setLoading(true);
        axios.post(host + "/package", {
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password"),
            ...packageInfo
        }).then((data) => {

            if (data.data.error) {
                alert(data.data.message)
            } else {
                getPackages()

                setVisible(false);
            }

            setLoading(false)
        }).catch((err) => {
            alert(err.message);
            setLoading(false)
        });
    }

    const editPackage = () => {
        setLoading(true);
        axios.put(host + "/package", {
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password"),
            ...packageInfo
        }).then((data) => {

            if (data.data.error) {
                alert(data.data.message)
            } else {
                getPackages()

                setVisible(false);
            }

            setLoading(false)
        }).catch((err) => {
            alert(err.message);
            setLoading(false)
        });
    }

    const onClose = () => {
        setVisible(false);
    };

    const getPackages = () => {
        axios.get(host + "/package?username=" + localStorage.getItem("username") + "&password=" + localStorage.getItem("password"), {}).then((data) => {
            if (data.data.error) {
                alert(data.data.message)
            } else {
                setPackages(data.data.message);
            }

        }).catch((err) => {
            alert(err.message);
            setLoading(false)
        });
    }
    useEffect(() => {
        getPackages();
    }, []);
    return (
        <div>
            <div style={{ textAlign: "right", margin: "10px" }}>
                <Button type="primary" onClick={() => openDrawer("add")}>
                    Ավելացնել փաթեթ
                </Button>
            </div>
            <Drawer
                title={`Ավելացնել փաթեթ`}
                placement="right"
                size={size}
                onClose={onClose}
                visible={visible}
            >

                <div className={"form-block"}>
                    <Input size="large" value={packageInfo.name} onInput={(e) => {
                        packageInfo.name = e.target.value;
                        setPackageInfo({ ...packageInfo })
                    }} placeholder={"Անուն"} />
                </div>

                <div className={"form-block"}>
                    <Input size="large" value={packageInfo.visits_count} onInput={(e) => {
                        packageInfo.visits_count = e.target.value;
                        setPackageInfo({ ...packageInfo });
                    }} placeholder="Այցելություններ" type={"number"} />
                </div>

                <div className={"form-block"}>
                    <Input size="large" value={packageInfo.price} onInput={(e) => {
                        packageInfo.price = e.target.value
                        setPackageInfo({ ...packageInfo })
                    }} placeholder="Գին" type="number" />
                </div>
                <div className={"form-block"}>
                    <p>Այցելությունների տևողությունը</p>
                    <Row>
                        <Col flex={2}>
                            <Select style={{ width: "93%" }} size="large" onChange={(e) => {
                                packageInfo.schedule_type = e;
                                setPackageInfo({ ...packageInfo });
                            }} value={packageInfo.schedule_type} className="select-after">
                                <Option value="Օր">Օր</Option>
                                <Option value="Ամիս">Ամիս</Option>
                                <Option value="Տարի">Տարի</Option>
                            </Select>
                        </Col>
                        <Col flex={3}>
                            <Input size="large" value={packageInfo.schedule_time} onInput={(e) => {
                                packageInfo.schedule_time = e.target.value;
                                setPackageInfo({ ...packageInfo });
                            }} placeholder="Քանակ" type={"number"} />
                        </Col>
                    </Row>




                </div>
                <div className={"form-block"}>
                    {mode === "edit" ?
                        <Button style={{ backgroundColor: "orange" }} loading={loading} onClick={editPackage}>
                            խմբագրել
                        </Button>

                        :

                        <Button type="primary" loading={loading} onClick={addPackage}>
                            Ավելացնել
                        </Button>
                    }

                </div>
            </Drawer>
            <h1>Փաթեթներ</h1>
            <Table columns={columns} dataSource={packages} onChange={onChange} />

        </div>
    )
}

export default Packages;