import { Layout, Menu, Breadcrumb } from 'antd';
import React from 'react';
import {
  BrowserRouter,
  HashRouter,
  Routes,
  Route
} from "react-router-dom";
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Packages from './components/Packages';
import Payments from './components/Payments';
import Users from './components/Users';
import Layouts from './Layout';
import Scan from './components/Scan'
class App extends React.Component {

  render() {
    return (
      <HashRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/scan" element={<Scan />} />
          <Route path="/data" element={<Layouts />}>
            <Route path="/data/dashboard" element={<Dashboard />} />
            <Route index path="/data/users" element={<Users />} />
            <Route path="/data/payments" element={<Payments />} />
            <Route path="/data/packages" element={<Packages />} />
          </Route>
        </Routes>
      </HashRouter>
    );
  }
}
export default App;