import React, { useState, useEffect } from "react";
import { Card, Col, Row } from 'antd';
import { Pie, G2, Line } from '@ant-design/charts';

const Dashboard = () => {
    const G = G2.getEngine('canvas');
    const [info, setInfo] = useState([]);
    const asyncFetch = () => {
        fetch('https://gw.alipayobjects.com/os/bmw-prod/1d565782-dde4-4bb6-8946-ea6a38ccf184.json')
            .then((response) => response.json())
            .then((json) => setInfo(json))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    };
    const lineConfig = {
        info,
        padding: 'auto',
        xField: 'Date',
        yField: 'scales',
        annotations: [
            // 低于中位数颜色变化
            {
                type: 'regionFilter',
                start: ['min', 'median'],
                end: ['max', '0'],
                color: '#F4664A',
            },
            {
                type: 'text',
                position: ['min', 'median'],
                content: '中位数',
                offsetY: -4,
                style: {
                    textBaseline: 'bottom',
                },
            },
            {
                type: 'line',
                start: ['min', 'median'],
                end: ['max', 'median'],
                style: {
                    stroke: '#F4664A',
                    lineDash: [2, 2],
                },
            },
        ],
    };

    const data = [
        {
            type: '分类一',
            value: 100,
        },
        {
            type: '分类二',
            value: 200,
        },
        {
            type: '分类三',
            value: 300,
        },
        {
            type: '分类四',
            value: 100,
        },
        {
            type: '分类五',
            value: 100,
        },
        {
            type: '其他',
            value: 200,
        },
    ];
    const cfg = {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'type',
        radius: 0.75,
        legend: false,
        label: {
            type: 'spider',
            labelHeight: 40,
            formatter: (data, mappingData) => {
                const group = new G.Group({});
                group.addShape({
                    type: 'circle',
                    attrs: {
                        x: 0,
                        y: 0,
                        width: 40,
                        height: 50,
                        r: 5,
                        fill: mappingData.color,
                    },
                });
                group.addShape({
                    type: 'text',
                    attrs: {
                        x: 10,
                        y: 8,
                        text: `${data.type}`,
                        fill: mappingData.color,
                    },
                });
                group.addShape({
                    type: 'text',
                    attrs: {
                        x: 0,
                        y: 25,
                        text: `${data.value}个 ${data.percent * 100}%`,
                        fill: 'rgba(0, 0, 0, 0.65)',
                        fontWeight: 700,
                    },
                });
                return group;
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
    };
    const config = cfg;


    useEffect(() => {
        // asyncFetch();
    }, []);
    return (
        <div style={{ marginTop: "20px" }}>
            <div className="site-card-wrapper">
                <Row gutter={16}>
                    <Col span={8} style={{ color: "white" }}>
                        <Card title="Ընդհանուր այցելություններ" bordered={false}>
                            <h1 style={{ color: "#FF9B71", fontSize: "32px" }}>3000</h1>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title="Ընդհանուր վճարումներ" bordered={false}>
                            <h1 style={{ color: "#2B3A67", fontSize: "32px" }}>5000</h1>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title="Ընդհանուր օգտվողներ" bordered={false}>
                            <h1 style={{ color: "#2B3A67", fontSize: "32px" }}>5000</h1>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={8} style={{ color: "white" }}>
                        <Pie {...config} />
                    </Col>
                    <Col span={8} style={{ color: "white" }}>
                        <Pie {...config} />
                    </Col>
                    <Col span={8} style={{ color: "white" }}>
                        <Pie {...config} />
                    </Col>
                    <Col span={8}>
                        {/* <Line {...lineConfig} /> */}
                    </Col>
                </Row>
            </div>,
        </div>
    )

}

export default Dashboard;