import { Layout, Menu, Breadcrumb } from 'antd';
import React, { useState } from 'react';
import {
    PieChartOutlined,
    OrderedListOutlined,
    UserOutlined,
    MoneyCollectOutlined,
    QrcodeOutlined
}
    from '@ant-design/icons';
import 'antd/dist/antd.css';
import './App.css'
import logo from './logo.png';
import { Outlet, useNavigate } from 'react-router-dom';
import { useHistory } from "react-router-dom";

const { Header, Content, Footer, Sider } = Layout;

const Layouts = () => {
    let navigate = useNavigate();

    const [menuIndex, setMenuIndex] = useState("1");

    const setPage = (index) => {
        setMenuIndex(index);
        // eslint-disable-next-line default-case
        switch (index) {
            case "1":
                navigate(`/data/`);
                break;
            case "2":
                navigate(`/data/users`);
                break;
            case "3":
                navigate(`/data/payments`);
                break;
            case "4":
                navigate(`/data/packages`);
                break;
            case "5":
                navigate(`/scan`);
                break;
        }
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider >
                <div className="logo" >
                    <img src={logo} style={{ width: "100%" }} />
                </div>
                <Menu theme="dark" defaultSelectedKeys={[menuIndex]} mode="inline">
                    {/* <Menu.Item onClick={() => { setPage("1") }} key="1" icon={<PieChartOutlined />}>
                        Վահանակ
                    </Menu.Item> */}
                    <Menu.Item onClick={() => { setPage("2") }} key="2" icon={<UserOutlined />}>
                        Օգտատերեր
                    </Menu.Item>
                    <Menu.Item onClick={() => { setPage("3") }} key="3" icon={<MoneyCollectOutlined />}>
                        Վճարումներ
                    </Menu.Item>
                    <Menu.Item onClick={() => { setPage("4") }} key="4" icon={<OrderedListOutlined />}>
                        Փաթեթներ
                    </Menu.Item>
                    <Menu.Item onClick={() => { setPage("5") }} key="5" icon={<QrcodeOutlined />}>
                        Սկանավորել
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{ padding: 0 }} />
                <Content style={{ margin: '0 16px' }}>
                    <Outlet />
                </Content>
                <Footer style={{ textAlign: 'center' }}>DavClub ©{new Date().getFullYear()}</Footer>
            </Layout>
        </Layout>
    );
}
export default Layouts;